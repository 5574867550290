<template>
    <div>
        <Header />
        <Breadcrumbs :title="evenement.title" />
        <section class="blog-detail-page section-b-space ratio2_3">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 blog-detail">
                        <img :src='eventMedia(evenement.media)' class="img-fluid" alt="evenement" />
                        <h3>{{ evenement.title }}</h3>
                        <ul class="post-social">
                            <li>{{ evenement.created_at }}</li>
                            <!--<li>Posted By : Admin Admin</li>-->
                        </ul>
                        <p>{{ evenement.description }}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
  import Header from '../../components/header/appHeader'
  import Footer from '../../components/footer/appFooter'
  import Breadcrumbs from '../../components/widgets/breadcrumbs'
  import { mapGetters } from "vuex";
  import generalMixin from "../../mixins/generalMixin";

  export default {
    name: "eventDetails",
    mixins: [generalMixin],
    components: {
      Header,
      Footer,
      Breadcrumbs
    },

    methods: {

    },

    computed: {
      ...mapGetters('evenements', ['evenement'])
    },

    mounted() {
      this.$store.dispatch('evenements/getEventDetails', this.$route.params.id)
    }
  };
</script>

<style scoped>

</style>