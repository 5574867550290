<template>
  <div>
    <Header />
    <Breadcrumbs title="collection" />
    <section class="section-b-space ratio_asos">
      <div class="collection-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <sidebar @select-categorie ="filterByCategory"/>
            </div>

            <div class="collection-content col">
              <div class="page-main-content">
                <div class="row">
                  <div class="col-sm-12">
                    <!--TOP BANNER-->
                    <div class="top-banner-wrapper">
                      <a href="#">
                        <img
                          :src="productTopBanner.original_url"
                          class="img-fluid"
                          alt
                        />
                      </a>
                    </div>

                    <!--PRODUCT FILTER TAG-->
                    <!--COLLECTION PRODUCTS-->
                    <div class="collection-product-wrapper">
                      <div class="product-top-filter">
                        <div class="row">
                          <div class="col-12">
                            <div
                              class="product-filter-content"
                              v-if="pagination != null"
                            >
                              <div class="search-count">
                                <h5>
                                  Affichage des produits {{ pagination.from }} à
                                  {{ pagination.last }} de
                                  {{ pagination.total }} Résultats
                                </h5>
                              </div>
                              <div class="collection-view">
                                <ul>
                                  <li @click="gridView()">
                                    <i class="fa fa-th grid-layout-view"></i>
                                  </li>
                                  <li @click="listView()">
                                    <i
                                      class="fa fa-list-ul list-layout-view"
                                    ></i>
                                  </li>
                                </ul>
                              </div>
                              <div class="collection-grid-view">
                                <ul>
                                  <li>
                                    <img
                                      src="./../../assets/images/icon/2.png"
                                      @click="grid2()"
                                      class="product-2-layout-view"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="./../../assets/images/icon/3.png"
                                      @click="grid3()"
                                      class="product-3-layout-view"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="./../../assets/images/icon/4.png"
                                      @click="grid4()"
                                      class="product-4-layout-view"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="./../../assets/images/icon/6.png"
                                      @click="grid6()"
                                      class="product-6-layout-view"
                                    />
                                  </li>
                                </ul>
                              </div>
                              <div class="product-page-filter">
                                <select @change="onChangeSort($event)">
                                  <option value="all">
                                    Trier les éléments
                                  </option>
                                  <option value="a-z">
                                    Alphabétiquement, A-Z
                                  </option>
                                  <option value="z-a">
                                    Alphabétiquement, Z-A
                                  </option>
                                  <option value="low">
                                    prix, de bas en haut
                                  </option>
                                  <option value="high">
                                    prix, de haut en bas
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="product-wrapper-grid"
                        :class="{ 'list-view': listview == true }"
                      >
                        <div class="row">
                          <div class="col-sm-12">
                            <div
                              class="
                                text-center
                                section-t-space section-b-space
                              "
                              v-if="products.length <= 0"
                            >
                              <img
                                :src="'@/assets/images/empty-search.jpg'"
                                class="img-fluid"
                                alt
                              />
                              <h3 class="mt-3">
                                Désolé ! Nous n'avons pas pu trouver le produit
                                que vous cherchiez !!!
                              </h3>
                              <div class="col-12 mt-3">
                                <router-link
                                  :to="{ path: '/' }"
                                  class="btn btn-solid"
                                >
                                  Continuer à acheter
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-grid-box"
                            :class="{
                              'col-md-3': col4 == true,
                              'col-md-4': col3 == true,
                              'col-md-6': col2 == true,
                              'col-md-2': col6 == true,
                              'col-md-12': listview == true,
                            }"
                            v-for="(product, index) in products"
                            :key="index"
                          >
                            <div class="product-box">
                              <productBox
                                @opencartmodel="showCart"
                                @showCompareModal="showCoampre"
                                @openquickview="showQuickview"
                                @showalert="alert"
                                @alertseconds="alert"
                                :product="product"
                                :index="index"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="product-pagination mb-0"
                        v-if="
                          pagination != null &&
                          pagination.total > pagination.per_page
                        "
                      >
                        <div class="theme-paggination-block">
                          <div class="row">
                            <div class="col-xl-6 col-md-6 col-sm-12">
                              <nav aria-label="Page navigation">
                                <ul class="pagination">
                                  <li
                                    class="page-item"
                                    :class="{
                                      disable: pagination.prevPageUrl === null,
                                    }"
                                  >
                                    <a
                                      class="page-link"
                                      href="javascript:void(0)"
                                      @click="
                                        selectedPage(
                                          pagination.current_page - 1
                                        )
                                      "
                                    >
                                      <span aria-hidden="true">
                                        <i
                                          class="fa fa-chevron-left"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li
                                    class="page-item"
                                    v-for="(
                                      page_index, index
                                    ) in updatePaginate(
                                      1,
                                      pagination.current_page,
                                      pagination.last_page
                                    )"
                                    :key="index"
                                    :class="{
                                      active:
                                        page_index == pagination.current_page,
                                    }"
                                  >
                                    <a
                                      class="page-link"
                                      href="javascrip:void(0)"
                                      @click.prevent="selectedPage(page_index)"
                                      >{{ page_index }}</a
                                    >
                                  </li>
                                  <li
                                    class="page-item"
                                    :class="{
                                      disable: pagination.nextPageUrl === null,
                                    }"
                                  >
                                    <a
                                      class="page-link"
                                      href="javascript:void(0)"
                                      @click="
                                        selectedPage(
                                          pagination.current_page + 1
                                        )
                                      "
                                    >
                                      <span aria-hidden="true">
                                        <i
                                          class="fa fa-chevron-right"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-12">
                              <div class="product-search-count-bottom">
                                <h5>
                                  Affichage des produits {{ pagination.from }} à
                                  {{ pagination.last }} de
                                  {{ pagination.total }} Résultats
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--COLLECTION PRODUCTS-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <quickviewModel
      :openModal="showquickviewmodel"
      :productData="quickviewproduct"
    />
    <cartModel
      :openCart="showcartmodal"
      :productData="cartproduct"
      @closeCart="closeCartModal"
      :products="products"
    />
    <Footer />
  </div>
</template>

<script>
import productBox from "../../components/product-box/product-box";
import Header from "../../components/header/appHeader";
import Footer from "../../components/footer/appFooter";
import Breadcrumbs from "../../components/widgets/breadcrumbs";
import sidebar from "../../components/widgets/collection-sidebar";
import quickviewModel from "../../components/widgets/quickview";

import cartModel from "../../components/cart-modal/cart-modal-popup";

import { mapActions, mapGetters } from "vuex";
import bannerMixin from "../../mixins/bannerMixins";
import {EventBus} from "../../utils/utils";

export default {
  name: "index",

  components: {
    Header,
    Footer,
    Breadcrumbs,
    productBox,
    sidebar,
    quickviewModel,
    // compareModel,
    cartModel,
  },

  mixins: [bannerMixin],

  data() {
    return {
      allfilters: [],

      filterQuery: {
        page: 1,
        categories: [],
        sort: "all"
      },

      col2: false,
      col3: false,
      col4: true,
      col6: false,
      listview: false,

      swiperOption: {
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      showquickviewmodel: false,
      showcomparemodal: false,
      showcartmodal: false,

      quickviewproduct: {},
      cartproduct: {},
    };
  },

  methods: {
    async filterByCategory(category){
      this.filterQuery.categories = category
      await this.listProducts(this.filterQuery);
    },

    removeTags(val) {
      this.allfilters.splice(this.allfilters.indexOf(val), 1);
    },
    removeAllTags() {
      this.allfilters.splice(0, this.allfilters.length);
    },

    async onChangeSort(event) {
      this.filterQuery.sort = event.target.value
      await this.listProducts(this.filterQuery);
      // this.$store.dispatch("products/sortProducts", event.target.value);
    },

    gridView() {
      this.col4 = true;
      this.col2 = false;
      this.col3 = false;
      this.col6 = false;
      this.listview = false;
    },
    listView() {
      this.listview = true;
      this.col4 = false;
      this.col2 = false;
      this.col3 = false;
      this.col6 = false;
    },
    grid2() {
      this.col2 = true;
      this.col3 = false;
      this.col4 = false;
      this.col6 = false;
      this.listview = false;
    },
    grid3() {
      this.col3 = true;
      this.col2 = false;
      this.col4 = false;
      this.col6 = false;
      this.listview = false;
    },
    grid4() {
      this.col4 = true;
      this.col2 = false;
      this.col3 = false;
      this.col6 = false;
      this.listview = false;
    },
    grid6() {
      this.col6 = true;
      this.col2 = false;
      this.col3 = false;
      this.col4 = false;
      this.listview = false;
    },

    ...mapActions({
      listProducts: "products/fetchProducts",
    }),

    async selectedPage(page) {
      this.filterQuery.page = page
      await this.listProducts(this.filterQuery);
    },

    updatePaginate(limit, currentPage, lastPage) {
      if (limit === -1) {
        return 0;
      }
      if (limit === 0) {
        return lastPage;
      }
      const current = currentPage;
      const last = lastPage;
      const delta = limit;
      const left = current - delta;
      const right = current + delta + 1;
      let range = [];
      let pages = [];
      let l;
      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i);
        }
      }
      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1);
          } else if (i - l !== 1) {
            pages.push("...");
          }
        }
        pages.push(i);
        l = i;
      });
      return pages;
    },

    alert(item) {
      this.dismissCountDown = item;
    },
    showQuickview(item, productData) {
      this.showquickviewmodel = item;
      this.quickviewproduct = productData;
    },
    showCoampre(item, productData) {
      this.showcomparemodal = item;
      this.comapreproduct = productData;
    },
    closeCompareModal(item) {
      this.showcomparemodal = item;
    },
    showCart(item, productData) {
      this.showcartmodal = item;
      this.cartproduct = productData;
    },
    closeCartModal(item) {
      this.showcartmodal = item;
    },
  },

  computed: {
    ...mapGetters("products", ["products", "pagination"]),
  },

  async mounted() {
    await this.$store.dispatch("category/fetchCategories");
    await this.$store.dispatch("attribute/fetchAttributes");
    EventBus.$on("searchProductEvent", async (data) => {
      this.filterQuery.name = data
      await this.listProducts(this.filterQuery);
    })
    await this.listProducts(this.filterQuery);
    await this.getMedias("collectionTopBanner");
  },
};
</script>

<style scoped></style>
