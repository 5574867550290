<template xmlns:v-swiper="http://www.w3.org/1999/xhtml">
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="filter-main-btn" @click="filter = !filter">
            <span class="filter-btn btn btn-theme">
              <i class="fa fa-filter" aria-hidden="true"></i> Filtre
            </span>
        </div>
      </div>
    </div>

    <div class="collection-filter" :class="{ 'openFilterbar' : filter }">
      <div class="collection-filter-block" style="min-height:68vh; max-height: 70vh">
        <!-- brand filter start -->
        <div class="collection-mobile-back">
                    <span class="filter-back" @click="filter = !filter">
                      <i class="fa fa-angle-left" aria-hidden="true"></i> retour
                    </span>
        </div>
        <div class="collection-collapse-block open">
          <h3 class="collapse-block-title" v-b-toggle.category>Categories</h3>
          <b-collapse id="category" visible accordion="myaccordion" role="tabpanel">
            <div class="collection-collapse-block-content">
              <div class="collection-brand-filter color-filter">
                <div class="custom-control custom-checkbox collection-filter-checkbox"
                     v-for="(category,index) in categories"
                     :key="index"
                >
                  <input
                      type="checkbox"
                      class="custom-control-input"
                      v-model="categorySelect"
                      :id="category.id"
                      :value="category.id"
                      @change="filterProduct"
                  />
                  <label class="custom-control-label" :class="{selected: isActive(category.name)}"
                         v-bind:for="category.id">{{ category.name }}</label>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>


      <!-- side-bar single product slider start -->
      <div class="theme-card">
        <h5 class="title-border">Nouveau Produits</h5>
        <div class="offer-slider slide-1">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div>
                  <div class="media" v-for="(product,index) in getCategoryProduct('new').splice(0,3)"
                       :key="index" style="">
                    <router-link :to="{ path: '/produit/'+product.slug}">
                      <img
                          class="img-fluid"
                          style="height: auto"
                          :src="product.images.length > 0 ? product.images[0].original_url : ''"
                          :alt="product.name">
                    </router-link>
                    <div class="media-body align-self-center">
                      <!--                                            <div class="rating">-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                            </div>-->
                      <router-link :to="{ path: '/produit/'+product.slug}">
                        <h6>{{ product.name }}</h6>
                      </router-link>
                      <h4 v-if="product.sale">
                        {{
                          discountedPrice(product)
                        }}
                        <del>{{ product.currency.symbol }} {{ product.price | numeral('0,0.00') }}
                        </del>
                      </h4>
                      <h4 v-else>{{ product.currency.symbol }} {{
                          product.price | numeral('0,0.00')
                        }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide" v-if="getCategoryProduct('new').length >= 4">
                <div>
                  <div class="media"
                       v-for="(product, index) in getCategoryProduct('new').splice(3,3)"
                       :key="index">
                    <router-link :to="{ path: '/produit/'+product.slug}">
                      <img class="img-fluid" :src="product.images.length > 0 ? product.images[0].original_url : ''" alt>

                    </router-link>
                    <div class="media-body align-self-center">
                      <!--                                            <div class="rating">-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                                <i class="fa fa-star"></i>-->
                      <!--                                            </div>-->
                      <router-link :to="{ path: '/produit/'+product.slug}">
                        <h6>{{ product.name }}</h6>
                      </router-link>
                      <h4 v-if="product.sale">
                        {{
                          discountedPrice(product)
                        }}
                        <del>{{ product.currency.symbol }} {{ product.price | numeral('0,0.00') }}
                        </del>
                      </h4>
                      <h4 v-else>{{ product.currency.symbol }} {{
                          product.price | numeral('0,0.00')
                        }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev" slot="button-prev">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- side-bar single product slider end -->
      <!-- side-bar banner start here -->
      <!--            <div class="collection-sidebar-banner">
                      <a href="#">
                          <img :src="bannerimagepath" class="img-fluid"/>
                      </a>
                  </div>-->
      <!-- side-bar banner end here -->
    </div>
    <!-- silde-bar colleps block end here -->
  </div>
</template>
<script>
import {mapGetters, mapActions, mapState} from 'vuex'


// import axios from 'axios'


export default {
  name: 'collection-sidebar',

  data() {
    return {
      categorySelect: [],

      filter: false,
      bannerimagepath: require('@/assets/images/side-banner.png'),
      // value: [50, 550],
      // selectedcolor: [],
      // selectedbrand: [],
      // selectedsize: [],
      applyFilter: [],
      activeItem: 'category',
      swiperOption: {
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {},
  computed: {
    ...mapState('products', ['products']),
    ...mapGetters({
      computedFilter : 'products/filterQuery'
    }),
    ...mapGetters('category', ['categories']),
    ...mapGetters('attribute', ['attributes']),
    ...mapGetters({
      filterbyCategory: 'products/filterbyCategory',
    })
  },
  mounted() {
    this.$emit('priceVal', this.value)
  },
  methods: {
    ...mapActions({
      listProducts: "products/fetchProducts"
    }),

    filterProduct() {
      this.$emit("select-categorie", this.categorySelect);
    },

    getCategoryProduct(collection) {
      return this.products.filter((item) => {
        if (item[collection]) {
          return item
        }
      })
    },
    // getImgUrl(path) {
    //     return require('@/assets/images/' + path)
    // },
    // discountedPrice(product) {
    //     const price = product.price - (product.price * product.discount / 100)
    //     return price
    // },
    isActive(filterItem) {
      return this.applyFilter.indexOf(filterItem) > -1
    },
    // appliedFilter() {
    //     this.$emit('allFilters', this.applyFilter)
    // },
    sliderChange(event) {
      this.$emit('priceVal', event)
    },
    toggleSidebarBlock() {
      this.openBlock = !this.openBlock
    },
    // getCategoryFilter(category) {
    //     this.$store.dispatch('products/getCategoryFilter', category)
    // }
  }
}
</script>

<style scope>
.collection-filter-block {
  padding: 0 10px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter {
  max-height: 22em;
}
</style>
