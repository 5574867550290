<template xmlns:v-swiper="http://www.w3.org/1999/xhtml">
  <div>
    <!-- <h2 class="title">{{ $t('home.title') }}</h2> -->
    <!-- <h2 class="subtitle">{{ $t('home.introduction') }}</h2> -->
    <!-- Home slider -->
    <section class="p-0">
      <div class="slide-1 home-slider">
        <div v-swiper:mySwiper="swiperOption">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in items.slice(0, 3)" :key="index">
              <div
                  class="home"
                  :class="alignClass"
                  v-bind:style="{ 'background-image': 'url(' + eventMedia(item.media) + ')' }"
              >
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="slider-contain">
                        <div>
                          <h3>{{ item.title }}</h3>
                          <h5>{{ item.description.substring(0, 100) + '...' }}</h5>
                          <router-link :to="{ path: '/evenement/'+item.id}" class="btn btn-solid">Lire</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </div>
      </div>
    </section>
    <!-- Home slider end -->
  </div>
</template>

<script>

import generalMixin from "../../../mixins/generalMixin";

export default {
  name: "slider",

  mixins: [generalMixin],

  props: [
    'items'
  ],

  data() {
    return {
      alignClass: 'p-left',

      swiperOption: {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },


}
</script>

<style scoped>

</style>
