<template>
    <div>
        <Header/>
        <Breadcrumbs title="Opportunités"/>
        <!-- about section start -->
        <section class="section-b-space">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-section">
                            <img :src="opportunityBanner.original_url " class="img-fluid" alt/>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="dashboard-right">
                            <div class="dashboard">
                                <div class="page-title">
                                    <h2>{{aboutTitle}}</h2>
                                </div>
                                <div class="welcome-msg">
                                    <p>ASCENSION GROUP vous offre l’opportunité de gagner beaucoup d’argents et de mener
                                        une vie
                                        de confort et de dignité. Vous serez votre propre patron, vous travaillerez au
                                        rythme et aux
                                        heures qui vous conviennent. Vous serez à l’abri de l’insécurité financière,
                                        vous aurez plus de
                                        temps pour vous-même et pour votre famille. Vous pourrez voyager, visiter le
                                        monde, jouir
                                        votre vie et réaliser vos rêves.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- about section end -->
        <!--Testimonial start-->
        <!--    <section class="testimonial small-section">-->
        <!--      <div class="container">-->
        <!--        <div class="row">-->
        <!--          <div class="col-sm-12">-->
        <!--            <div v-swiper:mySwiper="swiperOption">-->
        <!--              <div class="swiper-wrapper testimonial-slider">-->
        <!--                <div class="swiper-slide" v-for="(item, index) in items" :key="index">-->
        <!--                  <div class="media">-->
        <!--                    <div class="text-center">-->
        <!--                      <img :src="item.imagepath" alt="#" />-->
        <!--                      <h5>{{item.name}}</h5>-->
        <!--                      <h6>{{item.role}}</h6>-->
        <!--                    </div>-->
        <!--                    <div class="media-body">-->
        <!--                      <p>{{item.decs}}</p>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </section>-->
        <!--Testimonial ends-->
        <!--Team start-->
        <!--    <section id="team" class="team section-b-space ratio_asos">-->
        <!--      <div class="container">-->
        <!--        <div class="row">-->
        <!--          <div class="col-sm-12">-->
        <!--            <h2>Our Team</h2>-->
        <!--            <div v-swiper:mySwiper2="swiperOption2">-->
        <!--              <div class="swiper-wrapper">-->
        <!--                <div class="swiper-slide" v-for="(team, index) in teams" :key="index">-->
        <!--                  <div>-->
        <!--                    <div>-->
        <!--                      <img :src="team.imagepath" class="img-fluid" alt />-->
        <!--                    </div>-->
        <!--                    <h4>{{team.name}}</h4>-->
        <!--                    <h6>{{team.post}}</h6>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </section>-->
        <!--Team ends-->
        <Footer/>
    </div>
</template>
<script>
  import Header from "../../components/header/appHeader";
  import Footer from "../../components/footer/appFooter";
  import Breadcrumbs from "../../components/widgets/breadcrumbs";
  import bannerMixin from "../../mixins/bannerMixins";

  export default {
    components: {
      Header,
      Footer,
      Breadcrumbs
    },

    mixins: [bannerMixin],

    data() {
      return {
        swiperOption: {
          slidesPerView: 2,
          spaceBetween: 20,
          breakpoints: {
            991: {
              slidesPerView: 1,
              spaceBetween: 20
            }
          }
        },
        swiperOption2: {
          slidesPerView: 4,
          spaceBetween: 20,
          breakpoints: {
            1199: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            420: {
              slidesPerView: 1,
              spaceBetween: 20
            }
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        },
        items: [
          {
            imagepath: require("@/assets/images/2.jpg"),
            name: "Mark Jecno",
            role: "designer",
            decs:
              "you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings."
          },
          {
            imagepath: require("@/assets/images/20.jpg"),
            name: "Amy Molive",
            role: "tester",
            decs:
              "you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings."
          }
        ],
        teams: [
          {
            imagepath: require("@/assets/images/team/1.jpg"),
            name: "Hileri Keol",
            post: "chairman"
          },
          {
            imagepath: require("@/assets/images/team/2.jpg"),
            name: "Peter Pants",
            post: "business executive"
          },
          {
            imagepath: require("@/assets/images/team/3.jpg"),
            name: "john dio",
            post: "project manager"
          },
          {
            imagepath: require("@/assets/images/team/4.jpg"),
            name: "Petey Cruiser",
            post: "web developer"
          }
        ],
        aboutbanner: require("@/assets/images/about/about-us.jpg"),
        aboutTitle: "Ascension Group"
      };
    },

    mounted() {
      this.getMedias("opportunityBanner");
    }
  };
</script>
