<template>
  <div>
    <Header />
    <!-- about section start -->
    <section class="about-page section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <p class="success-text" style="font-size: xx-large; color: #19a340">
              Votre paiement a ete fait avec succes.
            </p>
            <p class="success-text" style="font-size: x-large; color: #19a340">
              Votre id de la transaction est
              {{ this.$route.query.transactionId }}.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Header from "../../components/header/appHeader";
import Footer from "../../components/footer/appFooter";
import bannerMixin from "../../mixins/bannerMixins";
export default {
  components: {
    Header,
    Footer,
  },

  mixins: [bannerMixin],

  data() {
    return {};
  },

  created() {
    this.$store.dispatch("auth/paymentSucess")
  },
};
</script>
