<template>
    <div>
        <Header/>
        <Breadcrumbs title="Contact"/>
        <section class="contact-page section-b-space">
            <div class="container">
                <div class="row section-b-space">
                    <div class="col-lg-7 map">
                        <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1605.811957341231!2d25.45976406005396!3d36.3940974010114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1550912388321"
                                allowfullscreen
                        ></iframe>
                    </div>
                    <div class="col-lg-5">
                        <div class="contact-right">
                            <ul>
                                <li>
                                    <div class="contact-icon">
                                        <img :src="phoneimage" alt="Generic placeholder image"/>
                                        <h6>Contactez-nous</h6>
                                    </div>
                                    <div class="media-body">
                                        <p>{{ telephone }}</p>
                                        <!--<p>{{phone2}}</p>-->
                                    </div>
                                </li>
                                <li>
                                    <div class="contact-icon">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        <h6>Addresse</h6>
                                    </div>
                                    <div class="media-body">
                                        <p v-html="addresse"></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="contact-icon">
                                        <img :src="emailimage" alt="Generic placeholder image"/>
                                        <h6>Email</h6>
                                    </div>
                                    <div class="media-body">
                                        <p>{{contact_email}}</p>
                                    </div>
                                </li>
                                <!--<li>-->
                                <!--<div class="contact-icon">-->
                                <!--<i class="fa fa-fax" aria-hidden="true"></i>-->
                                <!--<h6>Fax</h6>-->
                                <!--</div>-->
                                <!--<div class="media-body">-->
                                <!--<p>{{email1}}</p>-->
                                <!--<p>{{email2}}</p>-->
                                <!--</div>-->
                                <!--</li>-->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form class="theme-form">
                            <div v-if="errors.length">
                                <ul class="validation-error mb-3">
                                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                                </ul>
                            </div>
                            <div class="form-row" v-if="!user">
                                <div class="col-md-6">
                                    <label for="fname">Prenom</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="fname"
                                            v-model="form.fname"
                                            placeholder="Prenom"
                                            name="fname"
                                            required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="lname">Nom</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="lname"
                                            v-model="form.lname"
                                            placeholder="Nom"
                                            name="lname"
                                            required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="phone">Numero de contact</label>
                                    <input
                                            type="tel"
                                            class="form-control"
                                            id="phone"
                                            v-model="form.phone"
                                            placeholder="# Telephone"
                                            name="phone"
                                            required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Email</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="email"
                                            v-model="form.email"
                                            placeholder="Email"
                                            name="email"
                                            required
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <label for="message">Ecrivez votre message</label>
                                    <textarea
                                            class="form-control"
                                            placeholder="Ecrivez votre message..."
                                            id="message"
                                            v-model="form.message"
                                            name="message"
                                            rows="6"
                                    ></textarea>
                                </div>
                                <div class="col-md-12">
                                    <button type="button"
                                            @click="sendMessafile"
                                            class="btn btn-solid"
                                    >Envoyez votre message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>
<script>
  import Header from "../../../components/header/appHeader";
  import Footer from "../../../components/footer/appFooter";
  import Breadcrumbs from "../../../components/widgets/breadcrumbs";
  import { mapActions, mapGetters } from "vuex";

  import axios from 'axios'

  export default {
    name: "contact",
    components: {
      Header,
      Footer,
      Breadcrumbs
    },
    data() {
      return {
        phoneimage: require("@/assets/images/icon/phone.png"),
        emailimage: require("@/assets/images/icon/email.png"),
        phone1: "+91 123 - 456 - 7890",
        phone2: "+86 163 - 451 - 7894",
        address: "ABC Complex,Near xyz, New York <br /> USA 123456",
        email1: "Support@Shopcart.com",
        email2: "info@shopcart.com",
        errors: [],

        form: {
          fname: null,
          lname: null,
          email: null,
          phone: null,
          message: null
        }

      };
    },

    watch: {
      user: {
        immediate: true,
        handler: function(val) {
          this.getCustomerDetails(val.customer_id);
        }
      }
    },

    methods: {
      ...mapActions({
        getCustomerDetails: "customer/fetchCustomers"
      }),

      checkForm: function() {
        this.errors = [];
        if (!this.form.fname) {
          this.errors.push("First name required.");
        }
        if (!this.form.lname) {
          this.errors.push("Last name required.");
        }
        if (!this.form.email) {
          this.errors.push("Email required.");
        } else if (!this.validEmail(this.form.email)) {
          this.errors.push("Valid email required.");
        }
        if (!this.form.phone) {
          this.errors.push("Phone Number required.");
        }
        if (!this.form.message) {
          this.errors.push("Message required.");
        }
        if (!this.errors.length) return true;
      },

      validEmail: function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },


      sendMessafile: async function() {
        let loader = this.$loading.show()
        if(this.isLogged){
          this.fillForm(this.user, this.customer)
        }

        if (this.checkForm()) {
          axios.post("sendmessage/message", this.form)
            .then(data => {
              if (data.data.success) {
                loader.hide()
                this.clearForm()
                this.$swal("Envoye",
                  data.data.message,
                  "success");
              } else {
                loader.hide()
                this.$swal("Echoue",
                  data.data.message,
                  "error");
              }
            })
            .catch(err => {
              loader.hide()
              this.$swal("Echoue",
                err.message,
                "error");
            })
        }
      },

      clearForm(){
        this.form.fname = '';
        this.form.lname = '';
        this.form.email = '';
        this.form.phone = '';
        this.form.message = '';
      },

      fillForm(user, customer) {
        this.form.fname = customer.fname;
        this.form.lname = customer.lname;
        this.form.email = user.email;
        this.form.phone = customer.tel;
      }
    },

    computed: {
      ...mapGetters("auth", ["isLogged", "user"]),
      ...mapGetters({
        customer: "customer/customer"
      }),
      ...mapGetters("setting", ["addresse", "telephone", "contact_email"])
    },

    mounted() {
      this.$store.dispatch("setting/fetchSettings");
    }
  };
</script>
