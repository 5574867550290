<template>
  <div>
    <Header />
    <Breadcrumbs title="Checkout" />
    <section class="section-b-space">
      <div class="container">
        <div class="checkout-page">
          <div class="row">
            <div class="col-lg-3 col-sm-12 col-xs-12">
              <div class="checkout-title">
                <h3>Adresse Facture</h3>
              </div>
              <div class="dashboard-right">
                <div class="dashboard">
                  <div class="welcome-msg">
                    <h3 v-if="form.customer!=''">
                      {{ form.customer.fname }} {{ form.customer.lname }}
                    </h3>
                    <h4>
                      <strong v-if="form.customer!=''">{{ form.customer.code }}</strong>
                    </h4>
                  </div>
                  <div class="box-account box-info">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="box">
                          <div class="box-title">
                            <h3>Info de Contact</h3>
                          </div>
                          <div class="box-content">
                            <h6 class="text-uppercase" v-if="form.customer!=''">
                              {{ form.customer.fname }}
                              {{ form.customer.lname }}
                            </h6>
                            <h6 v-if="form.customer!=''">{{ form.customer.user.email }}</h6>
                            <h6 v-if="form.customer!=''">{{ form.customer.tel }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="box">
                        <div class="box-title">
                          <h3>Carnet d'Adresse</h3>
<!--                          a<a href="/#/dashboard/setting">Manage Addresses</a
                          >-->
                          <h3><a href="/#/dashboard/setting">Gerer les adresses</a></h3>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <h6>Adresse de Facturation</h6>

                            <v-select id="billingAddress"
                                      :options="user.addresses"
                                      name="payment"
                                      class="style-chooser"
                                      label="adress"
                                      v-model="form.billing_address"
                            />

                            <address v-if="form.billing_address!=null">
                              {{ form.billing_address.adress }}, {{ form.billing_address.city.name }}
                            </address>
                          </div>
                          <div class="col-sm-12">
                            <h6>Adresse de Livraison</h6>

                            <v-select id="shippingAddress"
                            :options="user.addresses"
                            name="payment"
                            class="style-chooser"
                            label="adress"
                            v-model="form.shipping_address"
                            />

                            <address v-if="form.shipping_address!=null">
                              {{ form.shipping_address.adress }}, {{ form.shipping_address.city.name }}
                            </address>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 col-xs-12">
              <div class="checkout-details">
                <div class="order-box">
                  <section class="cart-section section-b-space">
                    <div class="container">
                    <div class="row">
                      <div class="col-sm-12">
                        <h2 style="color: var(--theme-deafult)">Rappel de la commande</h2>
                        <table
                            class="table cart-table table-responsive-xs"
                            v-if="cart.length"
                        >
                          <thead>
                          <tr class="table-head">
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Prix unitaire</th>
                            <th scope="col">Point unitaire</th>
                            <!--                            <th scope="col">action</th>-->
                            <th scope="col">Taxe</th>
                            <th scope="col">total</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in cart" :key="index">
                            <td>
                              <router-link :to="{ path: '/produit/' + item.id }">
                                <img :src="item.images[0].original_url" alt="" />
                              </router-link>
                            </td>
                            <td>
                              <router-link :to="{ path: '/produit/' + item.id }"><b>{{item.quantity}} x {{
                                  item.name
                                }}</b></router-link>
                              <div class="mobile-cart-content row">
                                <div class="col-xs-3">
                                  <h6 class="text-left">Prix unitaire: {{ item.product_price }}</h6>
                                  <h6 class="text-left">Point unitaire: {{ item.point }}</h6>
                                  <h6 class="text-left">Sous-total: {{item.currency.symbol}} {{ item.price * item.quantity }}</h6>
                                  <h6 class="text-left">Sous-total Point: {{ item.point * item.quantity }}</h6>
                                  <h6 class="text-left">Taxe: {{ (cartItemTaxeAmount[item.id] * item.quantity) | numeral("0,0.00") }} {{ item.currency.symbol }}</h6>
                                </div>

                              </div>
                            </td>
                            <td>
                              <h4>{{ item.product_price }}</h4>
                            </td>
                            <td>
                              <h4>{{ item.point }} pt(s)</h4>
                            </td>
                            <td>
                              <h4 class="td-color">
                                {{ (cartItemTaxeAmount[item.id] * item.quantity) | numeral("0,0.00") }} {{ item.currency.symbol }}
                              </h4>
                            </td>
                            <td>
                              <h4 class="td-color">
                                {{ (item.price * item.quantity) | numeral("0,0.00") }} {{ item.currency.symbol }}
                              </h4>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <table
                            class="table cart-table table-responsive-md" style="margin-top: 20px"
                            v-if="cart.length"
                        >
                          <tfoot>
                          <tr>
                            <td>Total Point :</td>
                            <td class="large">
                              <h4>{{ cartTotalPoint | numeral("0,0.00") }} pt(s)</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>Prix total :</td>
                            <td class="large">
                              <h4>
                                {{ cartTotal | numeral("0,0.00") }} {{ cart.length > 0 ? cart[0].currency.symbol : "" }}
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td>Taxe total :</td>
                            <td class="large">
                              <h4>
                                {{ cartTaxeTotal() | numeral("0,0.00") }} {{ cart.length > 0 ? cart[0].currency.symbol : "" }}
                              </h4>
                            </td>
                          </tr>
                          <tr v-if="paymentFee>0">
                            <td>Frais paiement :</td>
                            <td class="large">
                              <h4>
                                {{ paymentFee | numeral("0,0.00") }} {{ cart.length > 0 ? cart[0].currency.symbol : "" }}
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td><b>TOTAL :</b></td>
                            <td class="large">
                              <h4>
                                <b>{{ (cartTaxeTotal()+cartTotal+paymentFee) | numeral("0,0.00") }} {{ cart.length > 0 ? cart[0].currency.symbol : "" }}</b>
                              </h4>
                            </td>
                          </tr>
                          </tfoot>
                        </table>
                        <div class="payment-box" style="margin-top: 20px">
                          <div class="upper-box">
                            <h2 style="color: var(--theme-deafult)">Mode de paiement</h2>
                            <div class="payment-options row">
                              <div class="col-sm-12">
                                <v-select id="paymentMethod"
                                          :options="payments"
                                          name="payment"
                                          class="style-chooser"
                                          label="name"
                                          v-model="form.payment"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="text-right" v-if="payments.length>0">
                            <button
                                type="submit"
                                @click="order()"
                                v-if="cart.length"
                                class="btn-solid btn"
                            >
                              Placer la Commande
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>

                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import {
 // ValidationProvider,
//  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import { mapActions, mapGetters } from "vuex";
import Header from "../../../components/header/appHeader";
import Footer from "../../../components/footer/appFooter";
import Breadcrumbs from "../../../components/widgets/breadcrumbs";
import productMixins from "../../../mixins/productMixins";

import _ from "lodash";

import axios from "axios";

export default {
  mixins: [productMixins],

  components: {
    Header,
    Footer,
    Breadcrumbs,
 //   ValidationProvider,
 //   ValidationObserver,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters({
      cart: "cart/cartItems",
      cartTotal: "cart/cartTotalAmount",
      cartItemTaxeAmount: "cart/cartItemTaxeAmount",
      cartTotalPoint: "cart/cartTotalPoint",
      cartTotalCashBack: "cart/cartTotalCashBack",
      cartTaxTotal: "cart/cartTaxTotal",
      customer: "customer/customer",
    }),

    globalTotalFee() {
      return (
        this.cartTaxTotal +
        (_.filter(this.form.payment.fees, (o) => o.status) || []).reduce(
          (totalFee, cum) => {
            return (
              totalFee +
              parseFloat(
                cum.type === "PERCENT"
                  ? (this.cartTotal * cum.value) / 100
                  : cum.value
              )
            );
          },
          0
        )
      );
    },

    paymentFee(){
      return (
          (_.filter(this.form.payment.fees, (o) => o.status) || []).reduce(
              (totalFee, cum) => {
                return (
                    totalFee +
                    parseFloat(
                        cum.type === "PERCENT"
                            ? ((this.cartTaxeTotal()+this.cartTotal) * cum.value) / 100
                          // ? cum.value / 100
                            : cum.value
                    )
                );
              },
              0
          )
      );
    },
  },
  data() {
    return {
      form: {
        customer: "",
        billing_address: "",
        shipping_address: "",
        payment: "",
        items: [],
      },
      isGuest: false,

      payments: [],

      button_style: {
        label: "checkout",
        size: "medium", // small | medium | large | responsive
        shape: "pill", // pill | rect
        color: "blue", // gold | blue | silver | black
      },
    };
  },

  watch: {
    user: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.isGuest = false;
          this.form.customer = val;
          this.form.billing_address = this.form.shipping_address = val.addresses[0]
        } else {
          this.form.customer = "";
          this.form.billing_address = this.form.shipping_address = ""
          this.isGuest = true;
        }
      },
    },

    cart: {
      immediate: true,
      handler: function (val) {
        if (val && val.length > 0) {
          this.form.items = val.map((item) => ({
            product: item,
            qty_ordered: item.quantity,
          }));
        } else {
          this.form.items = [];
        }
      },
    },
  },

  methods: {
    ...mapActions({
      // getCustomerDetails: "customer/fetchCustomers",
      pay: "pay/pay",
    }),

    fillForm: function () {},

    getPaymentTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get("payment_types")
          .then((data) => {
            if (data.data.success) {
              this.payments = data.data.data.order;
              resolve(data);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    cartTaxeTotal() {
      let _self = this
      return this.cart.reduce((total, val) => {
        return total + _self.cartItemTaxeAmount[val.id] * val.quantity
      }, 0.0)
      // return Object.values(this.cartItemTaxeAmount).reduce((total, val) => {
      //   return total + val
      // }, 0.0)
    },

    order() {
      console.log('this.form', this.form)
      let loader = this.$loading.show();
      // console.log(JSON.stringify(Object.assign(this.form, {global_fee: this.paymentFee})))
      if(this.form.payment!=null && this.form.payment!='') {
   //     if(this.customer.address!=null && this.customer.billing_address && this.customer.shipping_address){
          axios
              .post(
                  "orders",
                  Object.assign(this.form, {global_fee: this.paymentFee})
              )
              .then(({data}) => {
                if (data.success) {
                  loader.hide();
                  this.$store.dispatch("cart/clearCart");
                  if(data.data.payment.gateway==='Moncash'){
                    loader.hide();
                    this.$swal({
                      title: '<strong>Paiement de la commande</strong>',
                      icon: 'success',
                      html: 'Votre commande a été enregistrée avec succès et mise en attente pour paiement.<br/>Cliquez sur le bouton payer ci-dessous pour payer avec MonCash.',
                      showCloseButton: true,
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonText: '<i class="fa fa-credit-card"></i> Payer',
                      confirmButtonColor: 'red',
                      cancelButtonText: 'Quitter',
                    })
                        .then(async (result) => {
                          if (result.isConfirmed) {
                            const payment = await this.pay({
                              id : data.data.id,
                              pay_object : 'COMMANDE'
                            });
                            if(payment.success){
                              window.location.href = payment.data.link
                            }else{
                              this.$swal("Oops", payment.message, "error");
                            }
                          }
                        });
                  }
                  else {
                    loader.hide();
                    this.$swal(
                        "Commande reussie",
                        "Votre commande a été enregistrée avec succès et mise en attente pour paiement.",
                        "success"
                    );
                    this.$router.push({path: "/dashboard/panier"});
                  }
                } else {
                  loader.hide();
                  this.$swal("Commande echoue", data.message, "warning");
                }
              })
              .catch((err) => {
                loader.hide();
                this.$swal("Service a la Clientele", err, "error");
                console.log(err);
              });
     /*   }else {
          loader.hide();
          this.$swal("S'il vous plaît, au moins une de vos adresses est vide. Cliquez sur Gérer adresses pour modifier vos adresses", '', "error");
        }*/
      }else{
        loader.hide();
        this.$swal("Il faut choisir une méthode de paiement", '', "error");
      }
    },

    onSubmit() {
      console.log("Form has been submitted!");
    },
  },

  mounted() {
    // this.getCustomerDetails(this.user.id);
    this.getPaymentTypes();

  },
};
</script>
<style>
@media (max-width: 1430px){
  .cart-section tbody tr td {
    min-width: 100px;
  }
  section{
    padding-top: 30px;
  }
  .cart-section tfoot tr td, .wishlist-section tfoot tr td {
    padding-top: 0;
    min-width: 150px;
  }
  .cart-section tfoot tr td.large, .wishlist-section tfoot tr td.large {
    padding-top: 0;
    min-width: 300px;
    text-align: left;
  }
  .cart-section tfoot tr td.large h4, .wishlist-section tfoot tr td.large h4 {
    text-align: left;
  }
}

</style>
