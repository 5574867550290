<template>
    <div class="col-xl-3 col-lg-4 col-md-5">
        <div class="event-sidebar">
            <div class="theme-card" v-if="newEvents.length > 0">
                <h4>Nouveaux Evenements</h4>
                <ul class="recent-event">
                    <li v-for="(event,index) in newEvents.slice(0, 6)" :key="index">
                        <div class="media">
                            <router-link :to="{ path: '/evenement/'+event.id}">
                                <img :src="eventMedia(event.media)" class="img-fluid" alt/>
                                <div class="media-body align-self-center">
                                    <h6>{{ event.created_at }}</h6>
                                    <p>{{ event.title }}</p>
                                </div>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="theme-card" v-if="oldEvents.length > 0">
                <h4>Evenements passes</h4>
                <ul class="popular-tag">
                    <li v-for="(event,index) in oldEvents.slice(0, 6)" :key="index">
                        <div class="media">
                            <router-link :to="{ path: '/evenement/'+event.id}">
                                <img :src="eventMedia(event.media)" class="img-fluid" alt/>
                                <div class="media-body align-self-center">
                                    <h6>{{ event.created_at }}</h6>
                                    <p>{{ event.title }}</p>
                                </div>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import generalMixin from "../../../mixins/generalMixin";

  export default {
    name: "eventSidebar",
    mixins: [generalMixin],
    computed: {
      ...mapGetters("evenements", ["newEvents", "oldEvents"])
    },
    methods: {}
  };
</script>

<style scoped>

</style>