<template>
    <div>
        <b-modal
                id="modal-cart"
                size="lg"
                centered
                title="Add-to-cart"
                :hide-footer="true"
                :hide-header="true"
                v-if="openCart"
        >
            <div class="row cart-modal">
                <div class="col-lg-12">
                    <button class="close" type="button" @click="closeCart(openCart)">
                        <span>×</span>
                    </button>
                    <div class="media">
                        <img
                                :src="productData.images[0].original_url"
                                class="img-fluid"
                                :alt="productData.name"
                        />
                        <div class="media-body align-self-center text-center">
                            <h5>
                                <i class="fa fa-check"></i>Acticle
                                <span>{{productData.name}}</span>
                                <span> ajouter dans votre panier.</span>
                            </h5>
                            <div class="buttons d-flex justify-content-center">
                                <router-link
                                        :to="{ path: '/dashboard/panier'}"
                                        class="btn-sm btn-solid mr-2"
                                >Voir Panier</router-link>
                                <router-link
                                        :to="{ path: '/checkout'}"
                                        class="btn-sm btn-solid mr-2"
                                >Commander</router-link>
                                <a class="btn-sm btn-solid" @click="closeCart(openCart)"
                                >Continuer a acheter</a>
                            </div>
                        </div>
                    </div>
                    <div class="product-section">
                        <div class="col-12 product-upsell text-center">
                            <h4>Frequemment acheter avec</h4>
                        </div>
                        <div class="row">
                            <div
                                    v-for="(product,index) in cartRelatedProducts().slice(0, 4)"
                                    :key="index"
                                    class="product-box col-sm-3 col-6">
                                <div class="img-wrapper">
                                    <div class="front">
                                        <router-link :to="{ path: '/produit/'+product.id}">
                                            <img
                                                    :src='product.images[0].original_url'
                                                    class="img-fluid"
                                                    :alt="product.name"
                                            />
                                        </router-link>
                                    </div>
                                    <div class="product-detail">
                                        <router-link :to="{ path: '/produit/'+product.id}">
                                            <h6>{{ product.name }}</h6>
                                        </router-link>
                                        <h4 v-if="product.sale">{{ discountedPrice(product)  }}
                                            <del>{{ product.price  }}</del>
                                        </h4>
                                        <h4 v-else>{{ product.price }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    // import { mapState, mapGetters } from 'vuex'
    export default {
        name:'cart-modal-popup',

        props: ['openCart', 'productData', 'products'],

        computed: {
        },
        methods: {
            closeCart(val) {
                val = false
                this.$emit('closeCart', val)
            },

            cartRelatedProducts() {
                return this.products.filter((item) => {
                            return item
                })
            },

            discountedPrice(product) {
                return product.price - (product.price * product.discount / 100)
            }
        }
    }
</script>
