<template>
    <div>
        <Header/>
        <Breadcrumbs title="Bureau virtuel"/>
        <div class="container">
            <div>
                <div id="mainnav" style="text-align: end;background-color: #f8f8f8;">
                    <VueResponsiveMenu
                            #default="{ menuItems, moreMenuItems }"
                            :nav="navigation"
                            :maxCharacters="135"
                            @menu-resized="menuResized"
                            @item-to-dropdown="itemToDropdown"
                            @item-to-menu="itemToMenu"
                            @menu-items="menuItems"
                            @more-menu-items="moreMenuItems"
                            label="name">
                        <div>
                            <ul class="nav justify-content-end dash-menu" data-vue-responsive-menu>

                                <li class="nav-item" v-for="item in menuItems" :key="item.id">
                                    <router-link :to="{ path: item.href }">
                                        {{ item.name }}
                                    </router-link>
                                </li>

                                <!-- More menu with the items that didn't fit -->
                                <li class="nav-item dropdown" v-if="moreMenuItems.length">
                                    <a href="#" v-if="menuItems.length === 0" class="nav-link dropdown-toggle"
                                       data-toggle="dropdown" role="button" aria-expanded="false">
                                        <i class="fa fa-bars sidebar-bar"></i>
                                    </a>
                                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
                                       aria-expanded="false" v-else>
                                        <i class="fa-thin fa-ellipsis-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-right" style="background-color: #f8f8f8;">
                                        <li class="nav-item" v-for="item in moreMenuItems" :key="item.id">
                                            <router-link :to="{ path: item.href }" class="dropdown-item">
                                                {{ item.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </VueResponsiveMenu>
                </div>
            </div>

        </div>
        <router-view/>

        <b-modal
                id="modal-change-password"
                size="lg"
                centered
                title="Changer Mot de Passe"
                :hide-footer="true"
                :hide-header="true"
                no-close-on-backdrop
                @hidden="changePassword(user.change_password)"
        >
            <div class="row" style="padding: 10px">
                <!--<div class="col-lg-12 text-right">-->
                <!--Date: {{ new Date(messageReading.created_at) | date }}-->
                <!--<br/><br/>-->
                <!--</div>-->
                <div class="col-lg-12">
                    <change-password :is-modal="true"/>
                    <br/><br/><br/>
                </div>
            </div>
        </b-modal>

        <Footer/>
    </div>
</template>
<script>
  import Header from "../../../components/header/appHeader";
  import Footer from "../../../components/footer/appFooter";
  import Breadcrumbs from "../../../components/widgets/breadcrumbs";
  import VueResponsiveMenu from "vue-responsive-menu";

  import { mapActions, mapGetters } from "vuex";
  import ChangePassword from "../../ascension/components/changePassword";

  export default {
    name: "dashboard",
    props: ["leftSidebarVal"],
    data() {
      return {
        openmobilenav: false,
        subnav: false,
        activeItem: "Accueil",

        navigation: [
          {
            id: 1,
            name: "Accueil",
            href: "/dashboard"
          },
          {
            id: 2,
            name: "Panier",
            href: "/dashboard/panier"
          },
          {
            id: 3,
            name: "Reseau",
            href: "/dashboard/networking"
          },
          {
            id: 4,
            name: "Rapports",
            href: "/dashboard/report"
          },
          {
            id: 5,
            name: "Inscription",
            href: "/dashboard/enregistrement"
          },
          {
            id: 6,
            name: "Message",
            href: "/dashboard/messages"
          },
          {
            id: 7,
            name: "Documents",
            href: "/dashboard/documents"
          },
          {
            id: 8,
            name: "Parametres",
            href: "/dashboard/setting"
          }
        ]
      };
    },
    components: {
      ChangePassword,
      Header,
      Footer,
      Breadcrumbs,
      VueResponsiveMenu
    },

    computed: {
      ...mapGetters("auth", ["isLogged", "user"]),
      ...mapGetters("message", ["unReadCount"])
    },

    watch: {
      user: {
        immediate: true,
        handler: function(val) {

          console.log("MERDE MERDE", val);

          if (val && val.change_password) {
            this.$bvModal.show("modal-change-password");
          }
        }
      }
    },

    methods: {
      ...mapActions({
        getCustomerDetails: "customer/fetchCustomers",
        getMessages: "message/fetchMessengers"
      }),

      changePassword(change_password) {
        if(change_password){
          this.$bvModal.show("modal-change-password");
        }
      },

      menuResized(n) {
        console.log(n);
      },

      itemToDropdown(obj) {
        console.log(obj);
      },

      itemToMenu(obj) {
        console.log(obj);
      },

      moreMenuItems(arr) {
        console.log("moreMenuItems", arr);
      },

      menuItems(arr) {
        console.log(arr);
      }
    },

    mounted() {
      // this.getLevels();
        this.changePassword(this.user.change_password)
    }
  };
</script>
<style scoped>
    .dash-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 1em;
    }

    .dash-menu li {
        clear: both;
        height: 2em;
    }

    .dash-menu li a {
        float: left;
        display: block;
        padding: 4px 10px 8px 10px;
        text-decoration: none;
        color: #666;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    /* Change background color and font color
    of parent items when mouse hover */
    .dash-menu li:hover a,
    .dash-menu li a:hover {
        background: #999;
        color: #fff;
    }

    /*
Applie to group of Child Items
Each Child Item will be invisible by default
*/
    .dash-menu ul {
        display: none;
    }

    /* Each Child Item will be visible if mouse hover */
    .dash-menu li:hover ul {
        display: block;
    }

    .dash-menu ul {
        list-style: none;
        float: left;
        margin: 0;
        padding: 4px 8px;
    }

    .dash-menu ul li {
        float: left;
        clear: none;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
        color: #999;
    }

    /*
Reset and re style
link of each child item
*/
    .dash-menu li:hover ul li a,
    .dash-menu ul li a {
        display: inline;
        padding: 0 6px 0 0;
        float: none;
        text-transform: uppercase;
        color: #999;
        background: none;
    }

    .dash-menu li:hover ul li a:hover,
    .dash-menu ul li a:hover {
        background: none;
        color: #000;
    }
</style>
