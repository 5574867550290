<template>
    <div>
        <Header />
        <Breadcrumbs title="Evènements" />
        <section class="contact-page section-b-space">
            <div class="container">
                <Slider :items="evenements"/>
            </div>
        </section>
        <section class="section-b-space blog-page ratio2_3">
            <div class="container">
                <div class="row">
                    <!--Blog sidebar start-->
                    <EventSidebar />
                    <!--Blog sidebar start-->
                    <!--Blog List start-->
                    <div class="col-xl-9 col-lg-8 col-md-7 order-sec">
                        <EventList />
                    </div>
                    <!--Blog List start-->
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
  import Header from '../../components/header/appHeader'
  import Footer from '../../components/footer/appFooter'
  import Breadcrumbs from '../../components/widgets/breadcrumbs'
  import Slider from "../../views/ascension/components/sliderEvent.vue";
  import EventList from "./eventComponent/eventList"
  import EventSidebar from "./eventComponent/eventSidebar"
  import { mapGetters } from "vuex";
  // import bannerMixins from "../../mixins/bannerMixins";

  export default {
    name: "event",

    // mixins: [ bannerMixins],

    components: {
      Header,
      Footer,
      Breadcrumbs,
      Slider,
      EventList,
      EventSidebar
    },

    computed: {
      ...mapGetters('evenements', ['evenements'])
    },

    mounted() {
      this.$store.dispatch('evenements/fetchEvenements')
      // this.getMedias('eventBanner')
    }
  };
</script>

<style scoped>

</style>
