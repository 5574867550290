<template>
  <section class="register-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h4 style="color: var(--theme-deafult)">Changer le mot de passe de votre compte</h4>
          <ValidationObserver ref="settingPasswordForm">
            <div class="theme-form">
              <div class="form-row">
                <div class="col-md-6">
                  <label>Mot de Passe Actuel</label>
                  <ValidationProvider rules="required" :customMessages="{
                      required: 'Actuel Mot de passe?'
                    }" v-slot="{ errors }" name="password">
                    <input
                        type="password"
                        class="form-control"
                        v-model="form.password"
                        required
                    />
                    <span class="validate-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <label>Nouveau Mot de Passe</label>
                  <ValidationProvider rules="required" vid="password" :customMessages="{
                      required: 'Nouveau Mot de passe?'
                    }" v-slot="{ errors }" name="password">
                    <input
                        type="password"
                        class="form-control"
                        v-model="form.newpassword"
                        required
                    />
                    <span class="validate-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 offset-md-6">
                  <label>Répéter nouveau mot de passe</label>
                  <ValidationProvider rules="required|confirmed:password" vid="confirmPassword" :customMessages="{
                      required: 'Confirmer Mot de passe',
                       confirmed : 'Les champs mot de passe ne correspondent pas'
                    }" v-slot="{ errors }" name="confirm_password">
                    <input
                        type="password"
                        class="form-control"
                        v-model="form.repeatednewpassword"
                        required
                    />
                    <span class="validate-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <br/>
                  <button @click="cancelChangePassword"
                          class="btn btn-solid " v-if="isModal"
                  >Plus tard
                  </button>
                  <button @click="checkCurrentPassword"
                          class="btn btn-solid ml-1"
                  >Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

export default {
  name: "changePassword",

  components: {
    ValidationProvider,
    ValidationObserver
  },


  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        password: "",
        newpassword: "",
        repeatednewpassword: ""
      },

    }
  },

  methods: {
    cancelChangePassword() {
      if (this.$store.dispatch("auth/logout")) {
        this.$router.push("/login");
      }
    },

    checkCurrentPassword: async function () {
      // this.$bvModal.hide("modal-change-password");
      let loader = this.$loading.show({
        zIndex: 9999,
      });
      const valid = await this.$refs.settingPasswordForm.validate();
      if (valid) {
        axios.post("changePassword", Object.assign(this.form, this.user))
            .then(data => {
              loader.hide();
              if (data.data.success) {
                // this.clearForm();
                if (this.$store.dispatch("auth/logout")) {
                  this.$router.push("/login");
                  this.$swal("Mot de Passe modifie",
                      data.data.message,
                      "success");
                }
              } else {
                this.$swal("Erreur",
                    data.data.message,
                    "error");
              }
            })
            .catch(err => {
              loader.hide();
              this.$swal("Erreur",
                  err.message,
                  "error");
            });
      }
    },

    clearForm() {
      this.form.password = "";
      this.form.newpassword = "";
      this.form.repeatednewpassword = "";
    },
  },


  computed: {
    ...mapGetters("auth", ["isLogged", "user"])
  },
};
</script>

<style scoped>
.modal-content {
  background: white;
}
</style>
