var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h4',{staticStyle:{"color":"var(--theme-deafult)"}},[_vm._v("Changer le mot de passe de votre compte")]),_c('ValidationObserver',{ref:"settingPasswordForm"},[_c('div',{staticClass:"theme-form"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Mot de Passe Actuel")]),_c('ValidationProvider',{attrs:{"rules":"required","customMessages":{
                    required: 'Actuel Mot de passe?'
                  },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",attrs:{"type":"password","required":""},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Nouveau Mot de Passe")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","customMessages":{
                    required: 'Nouveau Mot de passe?'
                  },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newpassword),expression:"form.newpassword"}],staticClass:"form-control",attrs:{"type":"password","required":""},domProps:{"value":(_vm.form.newpassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "newpassword", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 offset-md-6"},[_c('label',[_vm._v("Répéter nouveau mot de passe")]),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"confirmPassword","customMessages":{
                    required: 'Confirmer Mot de passe',
                     confirmed : 'Les champs mot de passe ne correspondent pas'
                  },"name":"confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.repeatednewpassword),expression:"form.repeatednewpassword"}],staticClass:"form-control",attrs:{"type":"password","required":""},domProps:{"value":(_vm.form.repeatednewpassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "repeatednewpassword", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('br'),(_vm.isModal)?_c('button',{staticClass:"btn btn-solid",on:{"click":_vm.cancelChangePassword}},[_vm._v("Plus tard ")]):_vm._e(),_c('button',{staticClass:"btn btn-solid ml-1",on:{"click":_vm.checkCurrentPassword}},[_vm._v("Sauvegarder ")])])])])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }